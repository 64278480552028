var site = {
	rate : 500,
	// prefix : '/phaus'
	prefix : ''
};

Object.size = function(obj) {
	var size = 0, key;
	for (key in obj) {
		if (obj.hasOwnProperty(key)){
			size++;
		}
	}
	return size;
};

(function($) {

  /*
  Variables 
  ***************************************************/

  var $wrapper, 
  $slider, 
  $preloader, 
  layoutMode,
  $body = $('html, body');

  /*
  Functions 
  ***************************************************/

  /*
  Smoothstate. AJAXify with CSS3 page transitions 
  ***************************************************/

  // TODO: have a look at this when we have more time
  // https://github.com/miguel-perez/smoothState.js#faq
  // http://elijahmanor.com/dont-initialize-all-the-things-in-jquery-ready/

  // 'use strict';
  // var content  = $('#page').smoothState({
  //     prefetch: true,
  //     pageCacheSize: 4,
  //     onStart: {
  //         duration: 250,
  //         render: function (url, $container) {
  //             content.restartCSSAnimations();
  //             $body.animate({
  //                 scrollTop: 0
  //             });
  //         }
  //     },
  //     onAfter : function($container, $content) {
  //         $.readyFn.execute();
  //         pageLoad();
  //     }
  // }).data('smoothState');

  $(function(){
    'use strict';
    var $page = $('#main'),
        options = {
          debug: true,
          prefetch: true,
          cacheLength: 2,
          forms: 'form',
          scroll: false,
          onStart: {
            duration: 1000, // Duration of our animation
            render: function ($container) {
              // Add your CSS animation reversing class
              $.scrollTo(0,{ duration: 300 });
              $container.addClass('is-exiting');
              // Restart your animation
              smoothState.restartCSSAnimations();
            }
          },
          onReady: {
            duration: 1000,
            render: function ($container, $newContent) {
              // Remove your CSS animation reversing class
              $container.removeClass('is-exiting');
              // Inject the new content
              $container.html($newContent);
            }
          },
          onAfter : function($container, $content) {
            pageLoad();
            // $.scrollTo(0,{ duration: 250 });
          }
        },
        smoothState = $page.smoothState(options).data('smoothState');

  });

  var mapStyle = [{"featureType":"all","elementType":"all","stylers":[{"saturation":-100},{"lightness":24}]}];
  
  function initializeMap(location,id) {
      var venue = new google.maps.LatLng(location.lat, location.lng);
      var mapOptions = {
        center: venue,
        scrollwheel: false,
        zoom: 16,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.DEFAULT
        },
        styles : mapStyle
      };

      var map = new google.maps.Map(document.getElementById(id),mapOptions);
      var icon;
      var prefix = '';
      // var prefix = '/takk';
      
      icon = {
        url: prefix + '/wp-content/themes/phaus-theme/build/img/dot.png',
        size: new google.maps.Size(17, 17),
        origin: new google.maps.Point(0,0),
        anchor: new google.maps.Point(8,8)
      };

      var marker = new google.maps.Marker({
        position: venue,
        map: map,
        icon: icon,
        animation: google.maps.Animation.DROP,
        title:location.title
      });
     

    }

  function pageLoad(){
    if( jQuery('.map').length ){
      initializeMap({ lat : $('#map').data('lat'), lng : $('#map').data('lng') },"map");
    }

    $('.back-to-top a').click(function(){
      $.scrollTo(0,{ duration: 700 });
      return false;
    });

    $('.mobile-toggle').click(function(){
      $('.menu').slideToggle('fast');
      $(this).toggleClass('active');
    });

    $('.main-header-intro .blog-post-readmore a').click(function(){
      $(this).parent().toggleClass('toggled');
      $('.full-text').slideToggle('fast');
      return false;
    });

  }

  $(document).ready(function(){
    pageLoad();
  });

})(jQuery);

